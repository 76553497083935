import { convertPercentToNumber } from './helpers';
import { MarkerWithLabel } from "@googlemaps/markerwithlabel"

// TODO: Нужно будет разделить эту функцию на страницы истории, проектов, наград. Либо сделать её более универсальной.
export function locomotivePageInit() {
    if ($('.scroll-container').length == 0) return;
    if ($('.scroll-container').hasClass('scroll-container_desktop') && $(window).width() < 767) return;

    var scrollmain = new LocomotiveScroll({
        el: document.querySelector(".scroll-container"),
        smooth: true,
        class: "is-inview",
        getDirection: true,
    });

    window.onload = function() {
        scrollmain.update();
    };

    $(document).on('locomotive-update', function() {
        scrollmain.update();
    });

    var scrollToTop = $('[name="sroll-to-top"]');

    scrollToTop.on('click', function() {
        scrollmain.scrollTo( 'top');
    });

    $(document).on("stop-scroll", function() {
        scrollmain.stop();
    });
    $(document).on("start-scroll", function() {
        scrollmain.start();
    });

    scrollmain.on("scroll", (event) => {
        if (event.scroll.y > $(window).height()) {
            scrollToTop.addClass('show');
        } else {
            scrollToTop.removeClass('show');
        }
    });

    if ($('.scroll-container').hasClass('site-wrapp_projects')) {
        var scrollMax = scrollmain.scroll.scrollbarHeight;
        var direction = null;

        $('.projects-page__scroll-btn').on('click', function() {
            scrollmain.scrollTo($('#target-scroll')[0], {
                duration: 2000,
            });
        });

        scrollmain.on("scroll", function (event) {
            if ($(window).width() < 767) {
                if (event.scroll.y > 100) {
                    $(".header").addClass("fixed");
                } else {
                    $(".header").removeClass("fixed");
                }
            }
        });

        // scrollmain.on("call", (func, state, event) => {
        //     switch (true) {
        //         case direction == "down" && state == "enter":
        //             $(`.${func}`).addClass("show");
        //             if (func == "projects-page__scene-frame-btn") {
        //                 $(".projects-page__scroll-btn").addClass("hidden");
        //                 $(document).trigger('projectsSliderStart');
        //             }
        //             break;
        //         case direction == "up" && state == "exit":
        //             $(`.${func}`).removeClass("show");
        //             if (func == "projects-page__scene-frame-btn") {
        //                 $(".projects-page__scroll-btn").removeClass("hidden");
        //                 $(document).trigger('projectsSliderStop');
        //             }
        //             break;
        //         case direction == "down" && state == "exit" && func == "projects-page__scene-frame-btn":
        //             $(document).trigger('projectsSliderStop');
        //             break;
        //         case direction == "up" && state == "enter" && func == "projects-page__scene-frame-btn":
        //             $(document).trigger('projectsSliderStart');
        //             break;
        //     }
        // });

        $(document).trigger('projectsSliderStart');
    }

    if ($('.scroll-container').hasClass('site-wrapp_history')) {
        var scrollMax = scrollmain.scroll.scrollbarHeight;
        var direction = null;

        scrollmain.on('scroll', function(event) {
            direction = event.direction;
            if (direction == "down" && $(window).width() > 767) {
                $(".header").fadeOut(500);
            }
            if ($(window).width() < 767) {
                if(event.scroll.y > 100){
                    $(".header").addClass("fixed");
                }
                else {
                    $(".header").removeClass("fixed");
                }
            }

        });

        scrollmain.on("call", (func, state, event) => {
            if (direction == "down" && state == "enter") {
                if (func == "header-years-activeted") {
                    $(".history__years").addClass("active");
                }
            } else if (direction == "up" && state == "exit") {
                if (func == "header-years-deactiveted") {
                    $(".history__years").removeClass("active");
                    $(".header").fadeIn(500);
                }
            }
            if (func == "history-item" && state == "enter") {
                $(".history__year").removeClass("active");
                $(`.history__year[data-year="${$(event.el).data("year")}"]`).addClass("active");
            }

            if (func == "history-item-mobile" && state == "enter") {
                var currentYear = $(event.el).data("year");
                $(document).trigger("switch-year", [currentYear]);
                $('.history-switcher__year-text').text(currentYear);
            }
        });

        $(document).on("popup-click-year", function(event, year) {
            scrollHistoryPage(year);
        })

        $(".history__year").on("click", function() {
            scrollHistoryPage($(this).data("year"));
        });

        function scrollHistoryPage(year) {
            var offsetScroll = $(window).width() > 767 ? -260 : -20;

            scrollmain.scrollTo($(`.history-container[data-year="${year}"]`)[0], {
                duration: 2000,
                offset: offsetScroll
            });
        }
    }

    if ($('.scroll-container').hasClass('site-wrapp_awards')) {
        let direction = null;


        scrollmain.on('scroll', function(event) {
            direction = event.direction;

            let progress = Math.round(event.scroll.y * 100 / event.limit.y);

            let percentMask = convertPercentToNumber(progress, 450, 120) + '%';

            $('.awards-page__container')
                .css('mask-size', percentMask)
                .css('-webkit-mask-size', percentMask);

            $('.awards-page__item').each(function() {
                let startSize = $(this).data('start-size') ? $(this).data('start-size').split(':') : [0.8, 0, 0];
                $(this).css('transform',  `scale(${convertPercentToNumber(progress, Number(startSize[0]), 1)}) translateX(${convertPercentToNumber(progress, Number(startSize[1]), Number(startSize[2]))}vw)`);
            });

            $('.awards-page__slider-shadow').css('opacity', convertPercentToNumber(progress, 1, 0))

            $('.awards-page__container-flashlight')
                .css('opacity',  convertPercentToNumber(progress, 0, 0.3));

            $('.awards-page__container-light')
                .css('opacity',  convertPercentToNumber(progress, 0.1, 0));
        });

        scrollmain.scrollTo(0);
        scrollmain.stop();
        
        window.onload = () => {
            setTimeout(() => {
                scrollmain.scrollTo('.awards-page__trigger-end');
            }, 1000);
        }

        scrollmain.on('call', (func, state, event) => {
            if (direction == 'down' && state == 'enter') {
                if (func == 'end') {
                    $('.awards__years').addClass('active');
                    $('.header').addClass('hidden');
                    $('.header').addClass('hidden');
                    $('.awards-page__container').addClass('active');
                    $('.awards-page__years').addClass('active');
                    $('.awards-page__buttons').addClass('show');
                    $(document).trigger('update-awards-slider');
                    $('.awards-page__wrapp').addClass('award-slider-init');
                    setTimeout(() => $('.awards-page__item').css('transform', 'unset'), 300);
                }
            } else if (direction == 'up' && state == 'enter') {
                if (func == 'start') {
                    $('.awards__years').removeClass('active');
                    $('.header').removeClass('hidden');
                    $('.awards-page__container').removeClass('active');
                }
            }
        });
    }
}

export function pathAnimate () {
    var vector = $(".handwritten-animate"),
    pathlength = vector.find("path").length,
    className = "handwritten-item",
    counter = 1;

    vector.find("path").each(function() {
        var totalPathLength = $(this)[0].getTotalLength();
        $(this).css("stroke-dasharray", totalPathLength);
        $(this).css("stroke-dashoffset", totalPathLength);
    });
    vector.addClass("show");
    initAnimatePath($(`.${className}_${counter}`));

    function initAnimatePath(path, callback) {
        if (path.length == 0) return;

        var pathDuration = path[0].getTotalLength();
        if (pathDuration > 400) {
            pathDuration = 400;
        }
        path.animate(
            { strokeDashoffset: 0 },
            {
                duration: pathDuration,
                easing: "linear",
                complete: function() {
                    counter++;
                    if (counter <= pathlength) {
                        initAnimatePath($(`.${className}_${counter}`));
                    } else {
                        $(document).trigger('handwritten-animate-done')
                    }
                },
            }
        );
    }
}

export function checkMobileDevice () {
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
        },
    };
    return isMobile.any();
}

export function fixHeightMobile () {
    if (checkMobileDevice() && $('.fixheight').length !== 0) {
        $('.fixheight').css('max-height', $(window).height());
        $('body').addClass('hidden');
    }
}

export function toggleSwitch () {
    var switchItem,
        thisToggle;

    $('.toggle').each(function() {
        if ($(this).data('position') == 'right') {
            var tswitch = $(this).find('.toggle__switch');
            tswitch.addClass(`animate-right`);
            if ($(this).find('.toggle__text').length !== 0) {
                $(this).find(`[data-side="right"]`).addClass('active');
            }
        } else {
            $(this).find(`[data-side="left"]`).addClass('active');
        }
    });

    $('.toggle').on('click', function(event) {
        handleToggle($(this));
    });

    function handleToggle(elem) {
        switchItem = $(elem).find('.toggle__switch');
        thisToggle = $(elem);
        if (thisToggle.data('position') == '') {
            switchItem.attr('data-enabled', false)
        }
        switch (thisToggle.data('position')) {
            case 'left':
                toggleSwitchMove(thisToggle, switchItem, thisToggle.data('position'));
                $(document).trigger('switchComplexMapMarkers', {
                    'all_markers': 'enable'
                });
                break;
            case 'right':
                toggleSwitchMove(thisToggle, switchItem, thisToggle.data('position'));
                $(document).trigger('switchComplexMapMarkers', {
                    'all_markers': 'disable'
                });
                break;
            default:
                return false;
        }
    }

    function toggleSwitchMove(toggle, tswitch, position) {
        var moveToPos = position == 'left' ? 'right' : 'left';
        toggle.data('position', moveToPos);
        tswitch.removeClass(`animate-${position}`);
        tswitch.addClass(`animate-${moveToPos}`);
        if (moveToPos == 'right') {
            toggle.addClass('active');
        } else {
            toggle.removeClass('active');
        }
        setTimeout(function() {
            if (thisToggle.find('.toggle__text').length !== 0) {
                thisToggle.find('.toggle__text').removeClass('active');
                thisToggle.find(`[data-side="${moveToPos}"]`).addClass('active');
            }
        }, 400);
    }

    if ($('.label-switcher').length) {
        $('.label-switcher').closest('div').find('input').on('change', function(){
            labelSwitcher();
        });
        labelSwitcher();
    }

    function labelSwitcher() {
        $('.label-switcher').closest('div').removeClass('checked');
        $('.label-switcher').closest('div').find('input:checked').closest('div').addClass('checked');
    }
}

// Карта с инфраструктурой
export function infrastructureMap(container) {
    if ($(container).length === 0) return
    const mapContainer = $(container);
    const config = mapContainer.data('map-settings');
    const items = mapContainer.data('map-items');
    const complexCoords = mapContainer.data('complex-coords').split(',');
    const center = { lat: parseFloat(complexCoords[0]), lng: parseFloat(complexCoords[1]) };
    const imageSize = $(window).width() > 767 ? {size: [90, 152], url: '/images/contact-dot.svg'}: {size: [53, 75], url: '/images/contact-dot_mobile.svg'};
    const complexDot = {
        url: imageSize.url,
        size: new google.maps.Size(imageSize.size[0], imageSize.size[1]),
    };

    const map = new google.maps.Map(mapContainer[0], {
        zoom: config['zoom'],
        center: center,
        disableDefaultUI: config['disableDefaultUI'],
        gestureHandling: config['gestureHandling'],
        keyboardShortcuts: config['keyboardShortcuts'],
        styles: JSON.parse(config['style']),
    });

    let markers = {};

    items.forEach((el, i) => {
        let markerIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="10" fill="${el.color}"></circle></svg>`;
        markers[i] = new MarkerWithLabel({
            position: new google.maps.LatLng(el.location[0], el.location[1]),
            clickable: true,
            draggable: false,
            map: map,
            icon: {
                url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markerIcon),
                scaledSize: new google.maps.Size(20, 20),
            },
            labelContent: el.title,
            labelAnchor: new google.maps.Point(20, -32),
            labelClass: "complex-infrastructure__marker",
            groupId: el.group,
            color: el.color,
            visible: true
        });

        google.maps.event.addListener(markers[i], 'mouseover', function() {
            let label = markers[i].label;
            label.className += ' show';
        });

        google.maps.event.addListener(markers[i], 'mouseout', function() {
            let label = markers[i].label;
            label.className = label.className.replace(' show', '');
        });

        let label = markers[i].label;
        label.labelDiv.style.borderColor = el.color;
    });

    let complexMarker = new google.maps.Marker({
        position: new google.maps.LatLng(complexCoords[0], complexCoords[1]),
        map: map,
        icon: complexDot,
    });

    $('[data-map-legend-group]').on('change', 'input', function () {
        let groupId = $(this).val();

        for (let markerId in markers) {
            let marker = markers[markerId];

            if (marker.groupId == groupId) {
                if ($(this).prop('checked')) {
                    marker.setVisible(true);
                } else {
                    marker.setVisible(false);
                }
            }
        }
    });

    $(document).on('switchComplexMapMarkers', function (e, data) {
        $('[data-map-legend-group]').each(function () {
            var input = $(this).find('input');
            if (data.all_markers == 'enable') {
                input.prop('checked', true);
                input.change();
            }
            if (data.all_markers == 'disable') {
                input.prop('checked', false);
                input.change();
            }
        })
    });

    $('.complex-infrastructure__btn_plus').on('click', function () {
        var currentZoom = map.getZoom();
        map.setZoom(currentZoom + 1);
    });

    $('.complex-infrastructure__btn_minus').on('click', function () {
        var currentZoom = map.getZoom();
        map.setZoom(currentZoom - 1);
    });
}
