export function convertPercentToNumber(percent, minNumber, maxNumber) {
    // Проверка на входные данные (процент должен быть в диапазоне от 0 до 100)
    if (percent < 0 || percent > 100) {
        throw new Error('Процент должен быть в диапазоне от 0 до 100');
    }
    // Вычисляем число на основе пропорции
    return minNumber + (maxNumber - minNumber) * (percent / 100);
}

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function copyToClipboard(text) {
    var input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = 0;
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);
}